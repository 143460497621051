import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ServerCard from "./ServerCard";

const apiKey = process.env.REACT_APP_KASM_API_KEY;
const apiSecret = process.env.REACT_APP_KASM_API_SECRET;
const kasmApiURL = process.env.REACT_APP_KASM_API_URL;

export default function ServerBrowser() {
  const [kasms, setKasms] = useState([]);
  const [loading, setLoading] = useState(true);

  const getKasmInfo = async () => {
    try {
      if (!apiKey || !apiSecret) {
        throw new Error("Missing API credentials");
      }

      const getKasmsResponse = await axios.post(
        `/api/public/get_kasms`,
        {
          api_key: apiKey,
          api_key_secret: apiSecret,
        },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );

      const kasms = getKasmsResponse.data.kasms;
      setKasms(kasms);
    } catch (error) {
      console.error("Error fetching Kasm info:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getKasmInfo();
  }, []);

  return (
    <Box className="App" sx={{ textAlign: "center", padding: "2em" }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {kasms.map((kasm, index) => (
            <ServerCard
              key={index}
              name={kasm.image.friendly_name} 
              location={kasm.server.zone_name}
              status={kasm.operational_status}
              url={`${kasmApiURL}/#/kasm/${kasm.kasm_id}`}
            />
          ))}
        </>
      )}
    </Box>
  );
}
