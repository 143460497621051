import React from "react";
import { Box, Typography, Grid } from "@mui/material";

const boxData = [
  { title: "ChatGPT", image: "/company-logos/ChatGPT_logo.svg", link: "https://chat.openai.com" },
  { title: "Chegg", image: "/company-logos/Chegg_2021_Icon.svg", link: "https://www.chegg.com" },
  { title: "Quizlet", image: "/company-logos/quizlet.png", link: "https://quizlet.com" },
];

export default function Apps() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          margin: "0 auto",
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 4, color: "black" }}>
          Portal
        </Typography>
        <Grid container spacing={4}>
          {boxData.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "2px solid #e0e0e0",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
                onClick={() => window.open(item.link, "_blank")}
              >
                <img src={item.image} alt={item.title} style={{ height: "100px", marginBottom: "10px" }} />
                <Typography variant="h6" sx={{ fontWeight: "bold", color: "black" }}>
                  {item.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
